import { fetchGet, fetchPost, fetchPostPic } from "../index";

//查询数据
export const getDealerData = (params: any): Promise<any> => {
  return fetchGet("/rvapi/uc/dealer/delay/page", { params });
};

//概览数据
export const getOverviewCount = (params: any): Promise<any> => {
  return fetchGet("/rvapi/uc/dealer/delay/count", { params });
};

//查看失败原因
export const viewRefuseReason = (id: string): Promise<any> => {
  return fetchGet(`/rvapi/uc/dealer/delay/refuse_reason/${id}`);
};
//当前行文件预览
export const previewFile = (
  applyTypeCode: string,
  type: string,
  ucId: string
): Promise<any> => {
  return fetchGet(
    `/rvapi/uc/dealer/delay/preview/${applyTypeCode}/${type}/${ucId}`
  );
};
//detail cpo行文件预览
export const cpoPreview = (type: string, id: string): Promise<any> => {
  return fetchGet(`/rvapi/uc/dealer/delay/cpo/preview/${type}/${id}`);
};
//detail 置换行文件预览
export const replacementPreview = (type: string, id: string): Promise<any> => {
  return fetchGet(`/rvapi/uc/dealer/delay/replacement/preview/${type}/${id}`);
};
//detail cpo提交
export const cpoSubmit = (id: any): Promise<any> => {
  return fetchPost(`/rvapi/uc/dealer/delay/cpo/submit/${id}`);
};
//detail 置换提交
export const replacementSubmit = (id: any): Promise<any> => {
  return fetchPost(`/rvapi/uc/dealer/delay/replacement/submit/${id}`);
};
//dealer detail 获取区域经理
export const getManager = (params: any): Promise<any> => {
  return fetchGet("/rvapi/uc/dealer/delay/region_user", { params });
};
//detail cpo上传证明
export const cpoUploadProve = (data = {}, params = {}): Promise<any> => {
  return fetchPostPic(`/rvapi/uc/dealer/delay/cpo/upload_prove`, data, {
    params,
  });
};
//detail 置换上传证明
export const replacementUploadProve = (
  data = {},
  params = {}
): Promise<any> => {
  return fetchPostPic(`/rvapi/uc/dealer/delay/replacement/upload_prove`, data, {
    params,
  });
};
