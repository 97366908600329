
import { defineComponent, reactive, ref, onMounted } from "vue";
import { message } from "ant-design-vue";
import {
  previewFile,
  cpoPreview,
  replacementPreview,
} from "@/API/delayUsedCar/dealer";
import { operationRecord } from "@/API/userCar";
import moment from "moment";
import { b64toBlob } from "@/utils/claim/b64toBlob";
import downloadFile from "@/utils/rv/downloadFile";
export default defineComponent({
  emits: ["update:visible", "close-view-visible"],
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    isViewData: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
  },
  setup(props, { emit }) {
    const docName = ref("");
    const tableHeight = 600;
    const aUrl = ref<string>("");
    const columns = reactive<any>([
      { title: "操作角色", dataIndex: "operationRole", align: "center" },
      {
        title: "操作动作",
        dataIndex: "operationAction",
        align: "center",
        width: 100,
      },
      { title: "操作时间", dataIndex: "updatedAt", align: "center" },
    ]);
    const dataSource = reactive<any>([
      {
        role: "管理员",
        handleaction: "上传文件",
        time: "2021/09/11",
      },
    ]);
    const viewList = ref<any>([]);
    const title = ref<string>("二手车发票");
    const fileName = ref<string>(props.isViewData.vinNo);
    const fileType = ref<string>("");
    const initTitle = () => {
      if (props.isViewData.type == 1) {
        title.value = "二手车发票:FP_";
      } else if (props.isViewData.type == 2) {
        title.value = "登记证:DJZ_";
      } else if (props.isViewData.type == 3) {
        title.value = "身份证明:SFZM_";
      } else if (props.isViewData.type == 4) {
        title.value = "寄售合同:JSHT_";
      } else if (props.isViewData.type == 5) {
        title.value = "销售合同:XSHT_";
      } else if (props.isViewData.type == 6) {
        title.value = "认证报告:RZBG_";
      } else if (props.isViewData.type == 7) {
        title.value = "保修报告:BXBG_";
      } else if (props.isViewData.type == 8) {
        title.value = "其它:QT_";
      } else {
        title.value = "证明:ZM_";
      }

      // if (props.isViewData.type == 1) {
      //   title.value = "二手车发票:";
      // } else if (props.isViewData.type == 2) {
      //   title.value = "登记证:";
      // } else if (props.isViewData.type == 3) {
      //   title.value = "身份证明:";
      // } else if (props.isViewData.type == 4) {
      //   title.value = "寄售合同:";
      // } else if (props.isViewData.type == 5) {
      //   title.value = "销售合同:";
      // } else if (props.isViewData.type == 6) {
      //   title.value = "认证报告:";
      // } else if (props.isViewData.type == 7) {
      //   title.value = "保修报告:";
      // } else {
      //   title.value = "其它:";
      // }
    };
    //初始化可查看页面 dealer
    const initDealer = () => {
      const applyTypeCode = props.isViewData.applyTypeCode;
      const ucId = props.isViewData.ucId;
      const type = props.isViewData.type;
      if (props.isViewData.applyTypeCode) {
        previewFile(applyTypeCode, type, ucId).then((res: any) => {
          docName.value = res.docName;
          fileType.value = res.docType;
          const temp = {
            imgUrl: "",
            downUrl: "",
            docType: true,
          };
          if (res.base64code) {
            temp.imgUrl = URL.createObjectURL(
              b64toBlob(res.base64code, "application/pdf")
            );
            temp.docType = false;
          } else {
            temp.imgUrl = res.docUrl;
            temp.docType = true;
          }
          temp.downUrl = res.docUrl;
          viewList.value.push(temp);
          aUrl.value = viewList.value.downUrl;
          return viewList.value;
        });
      }
    };
    // 初始化可查看页面 cpo
    const initCPO = () => {
      const type = props.isViewData.type;
      const id = props.isViewData.id;
      if (props.isViewData.types == 0) {
        // cpo 查看
        cpoPreview(type, id).then((res: any) => {
          docName.value = res.docName;
          fileType.value = res.docType;
          const temp = {
            imgUrl: "",
            downUrl: "",
            docType: true,
          };
          if (res.base64code) {
            temp.imgUrl = URL.createObjectURL(
              b64toBlob(res.base64code, "application/pdf")
            );
            temp.docType = false;
          } else {
            temp.imgUrl = res.docUrl;
            temp.docType = true;
          }
          temp.downUrl = res.docUrl;
          viewList.value.push(temp);
          aUrl.value = viewList.value.downUrl;
          return viewList.value;
        });
      }
    };
    //初始化 置换
    const initTradeIn = () => {
      const type = props.isViewData.type;
      const id = props.isViewData.id;
      if (props.isViewData.types == 1 && props.isViewData.id) {
        // 置换 查看
        replacementPreview(type, id).then((res: any) => {
          fileType.value = res.docType;
          const temp = {
            imgUrl: "",
            downUrl: "",
            docType: true,
          };
          if (res.base64code) {
            temp.imgUrl = URL.createObjectURL(
              b64toBlob(res.base64code, "application/pdf")
            );
            temp.docType = false;
          } else {
            temp.imgUrl = res.docUrl;
            temp.docType = true;
          }
          temp.downUrl = res.docUrl;
          viewList.value.push(temp);
          aUrl.value = viewList.value.downUrl;
          return viewList.value;
        });
      }
    };
    // 初始化操作记录信息
    const initRecord = () => {
      //通用接口
      operationRecord({
        mainId: props.isViewData.id,
        type: props.isViewData.operationType,
        isDealer: 1,
      })
        .then((res) => {
          dataSource.length = 0;
          dataSource.push(...res);
        })
        .catch((err) => {
          console.log(err, "报错信息");
        });
    };
    const isTab = ref<number>(0);
    const changeTab = (param: number) => {
      console.log(param, "这是参数");
      isTab.value = param;
      if (param == 1) {
        initRecord();
      }
    };
    // 下载全部图片
    const downLoadAllImg = () => {
      const params = {
        url: viewList.value[0].docUrl,
        method: "get",
        fileName: `${moment(new Date()).format("YYYYMMDDhhmmss")}${{
          title,
        }}.jpg`,
      };
      downloadFile(params);
    };
    onMounted(() => {
      console.log(props.isViewData.types);
      if (props.isViewData.types != undefined) {
        if (props.isViewData.types == 0) {
          initCPO();
        }
        if (props.isViewData.types == 1 && props.isViewData.id) {
          initTradeIn();
        }
      } else {
        initDealer();
      }
    }),
      initTitle();

    return {
      viewList,
      title,
      close,
      downLoadAllImg,
      changeTab,
      isTab,
      dataSource,
      columns,
      tableHeight,
      aUrl,
      fileName,
      fileType,
      docName,
    };
  },
});
